import CricketThumb from "../Media/cricket_1.jpeg"
import DriverThumb from "../Media/drowsiness-thumbnail.jpg"
import NesoThumb from "../Media/work-3.png"
import {Link} from "react-router-dom"

function MyWork() {
    return (
    <div id="portfolio">
        <div className="container">
            <h1 className="sub-title">My Work</h1>
            <div className="work-list">
                <div className="work">
                    <img src={CricketThumb} alt="project-thumbnail"/>
                    <div className="layer">
                        <h3>Event Detection in Cricket</h3>
                        <p>Detects various events associated with a single ball delivery in a cricket match</p>
                        <Link to="event-detection"><i className="fa-solid fa-arrow-up-right-from-square"></i></Link>
                    </div>
                </div>
                <div className="work">
                    <img src={DriverThumb} alt="project-thumbnail"/>
                    <div className="layer">
                        <h3>Driver Drowsiness Detection</h3>
                        <p>Identify and classify driver's eye state as alert or drowsy</p>
                        <Link to="driver-drowsiness-detection"><i className="fa-solid fa-arrow-up-right-from-square"></i></Link>
                    </div>
                </div>
                <div className="work">
                    <img src={NesoThumb} alt="project-thumbnail"/>
                    <div className="layer">
                        <h3>Neso Academy Website</h3>
                        <p>Developed the code for the Neso Academy website</p>
                        <Link to="neso-website"><i className="fa-solid fa-arrow-up-right-from-square"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default MyWork