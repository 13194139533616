function Education() {
    return (
    <div className="tab-contents" id="education">
        <ul>
            <li><span>2019 - 2023</span><br/>Bachelor of Technology in Electrical and Computer Science Engineering</li>
            <li><span>2017 - 2019</span><br/>Intermediate (Physics, Chemistry, and Maths)</li>
            <li><span>2015 - 2017</span><br/>High School</li>
        </ul>
    </div>
    )
}

export default Education