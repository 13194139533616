import MainImg from "../Media/mauj-img.jpg"
import Skills from "./Skills"
import Experience from "./Experience"
import Education from "./Education"
import { useState } from 'react';


function About() {

    const [value, setValue] = useState("skills");

    const tablinks = document.getElementsByClassName("tab-links")

    function handleChange(val, e) {
        setValue(val)

        for (let tablink of tablinks) {
            tablink.classList.remove("active-link")
        }

        e.currentTarget.classList.add("active-link")
    }    

    let componentToRender;
    if (value === "skills") {
        componentToRender = <Skills />;
    } else if (value === "experience") {
        componentToRender = <Experience />;
    } else if (value === "education") {
        componentToRender = <Education />;
    }

    return (
    <div id="about">
        <div className="container">
            <div className="row">
                <div className="about-col-1">
                    <img src={MainImg} alt="main-img"/>
                </div>
                <div className="about-col-2">
                    <h1 className="sub-title">About Me</h1>
                    <p>
                        Hello! I'm Mauj, a tech enthusiast and Associate Machine Learning Scientist at <span id="external_link"><a href="https://www.wadhwaniai.org/" target="_blank" rel="noreferrer">Wadhwani AI</a></span>. With a passion for innovation, I excel in Machine Learning, Deep Learning, Computer Vision, and Full Stack Web Development. With 14 months of internships and 6 months of full-time experience, I'm on a mission to make a meaningful impact. Let's turn ideas into reality together!
                    </p>
                    <div className="tab-titles">
                        <p className="tab-links active-link" onClick={(e) => handleChange("skills", e)}>Skills</p>
                        <p className="tab-links" onClick={(e) => handleChange("experience", e)}>Experience</p>
                        <p className="tab-links" onClick={(e) => handleChange("education", e)}>Education</p>
                    </div>
                    {componentToRender}
                </div>
            </div>
        </div>
    </div>
    )
}

export default About