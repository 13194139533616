import Logo from "../Media/logo2.png"
import {Link} from "react-router-dom"
import One from "../Media/one.mp4"
import Two from "../Media/two.mp4"
import Three from "../Media/three.mp4"


function DriverProject() {
    return (
    <>
    <div id="header-event">
        <div className="container">
            <nav>
                <Link to="/"><img src={Logo} className="logo" alt="logo"/></Link>
            </nav>
        </div>
    </div>
    <div id="services">
        <div className="container" id="project-container">
            <h1 className="sub-title" id="project-heading">Driver Drowsiness Detection</h1>
            <br/>
            <p>Identify and classify driver's eye state as alert or drowsy - <br/>
                <br/> <i className="fa-solid fa-arrow-right"></i> Detects the eyes of the driver.
                <br/> <i className="fa-solid fa-arrow-right"></i> Classfies the detected eyes as alert or drowsy using custom built model.

                <br/> <br/>
                Future Work -  <br/>
                <br/><i className="fa-solid fa-arrow-right"></i> Increasing the size and diversity of the training dataset, which will make the model more robust.
                <br/><i className="fa-solid fa-arrow-right"></i> Focussing more than just on eyes to classify the driver state (drowsy/alert).
                <br/> <br/>
                Please check out the driver drowsiness detection algorithm in action in the videos (might have to use the full screen option because of the font) - 
            </p>
            <div className="services-list">
                <div className="video-div driver-project">
                    <video controls>
                        <source src={One} type="video/mp4" />
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
                <div className="video-div driver-project">
                    <video controls>
                        <source src={Two}/>
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
                <div className="video-div driver-project">
                    <video controls>
                        <source src={Three}/>
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
            </div>
            <div id="know-more">
                <h3>Want to know more? Click on the page below</h3>
                <br/>
                <a href="https://drive.google.com/file/d/1XmlZ4YDLI4dn59okzzaPWQNu9ST7fhSj/view?usp=sharing" rel="noreferrer" target="_blank"><i className="fa-solid fa-file"></i></a>
            </div>
        </div>
    </div>
    </>
    )
}

export default DriverProject