function Contact() {

    const scriptURL = "https://script.google.com/macros/s/AKfycbwrriAcGLhppWT6kH6Ak9LFVnIgkgxUELN4uJOfFLc1PMj4f6zbnw7j9j8lvVEhvExO/exec"

    async function fill_sheet() {
        const msg = document.getElementById("msg")
        msg.innerHTML = "Sending Message"
        const form = document.forms['submit-to-google-sheet']
        const res = await fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        
        if (res.status < 300) {
            msg.innerHTML = "Message Sent Successfully"
            setTimeout(() => {
                msg.innerHTML = ""
                form.reset()
            }, 5000)
        }
    }

    function handle_submit(e) {
        e.preventDefault()
        fill_sheet()
    }

    function handle_click(e) {
        if (e.key === "Enter") {
            if (validateForm()) {
            handle_submit(e)
            }
        }
    }

    function validateForm() {
        var inputField = document.getElementById("input-text").value.trim();
        var emailField = document.getElementById("input-email").value.trim();
      
        if (inputField === "" || emailField === "") {
          alert("Please fill in all required fields.");
          return false
        }
      
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(emailField)) {
          alert("Please enter a valid email address.");
          return false;
        }
    }

    return (
    <div id="contact">
        <div className="container">
            <div className="row">
                <div className="contact-left">
                    <h1 className="sub-title">Contact Me</h1>
                    <p><i className="fa-solid fa-paper-plane"></i>mauj.mishra@gmail.com</p>
                    <p><i className="fa-solid fa-square-phone"></i>+91-6395581262</p>
                    <div className="social-icons">
                        <a href="https://www.linkedin.com/in/mauj-mishra-455b161a4/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="https://github.com/MaujMishra" target="_blank" rel="noreferrer"><i className="fa-brands fa-github"></i></a>
                    </div>
                    <a href="https://drive.google.com/file/d/1ZnMMwJQYQermjKQa5HJ6my6k5QY44omY/view?usp=sharing" className="btn btn2" target="_blank" rel="noreferrer">View CV</a>
                </div>
                <div className="contact-right">
                    <form name="submit-to-google-sheet" onSubmit={(e) => handle_submit(e)} onKeyUp={(e) => handle_click(e)}>
                        <input type="text" id="input-text" name="Name" placeholder="Your Name" required/>
                        <input type="email" id="input-email" name="Email" placeholder="Your Email" required/>
                        <textarea name="Message" rows="6" placeholder="Your Message"></textarea>
                        <button type="submit" className="btn btn2 btn btn-danger">Submit</button>
                    </form>
                    <span id="msg"></span>
                </div>
            </div>
        </div>
        <div className="copyright">
            <p>Copyright © Mauj </p>
        </div>
    </div>
    )
}

export default Contact