import Logo from "../Media/logo2.png"
import {Link} from "react-router-dom"
import Test1 from "../Media/test1_output.mp4"
import Test2 from "../Media/test2_output.mp4"
import Test3 from "../Media/test3_output.mp4"



function CricketProject() {
    return (
    <>
    <div id="header-event">
        <div className="container">
            <nav>
                <Link to="/"><img src={Logo} className="logo" alt="logo"/></Link>
            </nav>
        </div>
    </div>
    <div id="services">
        <div className="container" id="project-container">
            <h1 className="sub-title" id="project-heading">Event Detection In Cricket</h1>
            <br/>
            <p>Detects various events associated with a single ball delivery in a cricket match - <br/> 
                <br/> <i className="fa-solid fa-arrow-right"></i> Ball release point identification from the bowler.
                <br/> <i className="fa-solid fa-arrow-right"></i> Bounce point identification on the pitch.
                <br/> <i className="fa-solid fa-arrow-right"></i> Hit classification by the batsman.
                <br/> <i className="fa-solid fa-arrow-right"></i> Shot direction identification.

                <br/> <br/>

                Future Work -  <br/>
                <br/><i className="fa-solid fa-arrow-right"></i> Future work focusses on implementing models and algorithms to detect more events.
                <br/><i className="fa-solid fa-arrow-right"></i> An NLP model will be built which will take input the events detected, and output the commentary of a single delivery.
                
                <br/> <br/>
                Please check out the event detection algorithm in action in the videos (might have to use the full screen option because of the font) - 
            </p>
            <div className="services-list">
                <div className="video-div">
                    <video controls>
                        <source src={Test1} type="video/mp4"/>
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
                <div className="video-div">
                    <video controls>
                        <source src={Test2} type="video/mp4"/>
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
                <div className="video-div">
                    <video controls>
                        <source src={Test3} type="video/mp4"/>
                        <p>Your browser does not support the video.</p>
                    </video>
                </div>
            </div>
            <div id="know-more">
                <h3>Want to know more? Click on the page below</h3>
                <br/>
                <a href="https://drive.google.com/file/d/1WZEm85aRqJexj0AcBVJ4zg2N3BmBmEzH/view?usp=drive_link" rel="noreferrer" target="_blank"><i className="fa-solid fa-file"></i></a>
            </div>
        </div>
    </div>
    </>
    )
}


export default CricketProject