import Logo from "../Media/logo2.png"
import {Link} from "react-router-dom"
import One from "../Media/subjectpage.mp4"
import Two from "../Media/pdfpage.mp4"
import Three from "../Media/searchpage.mp4"

function NesoProject() {
    return (
    <>
    <div id="header-event">
            <div className="container">
                <nav>
                    <Link to="/"><img src={Logo} className="logo" alt="logo"/></Link>
                </nav>
            </div>
        </div>
        <div id="services">
            <div className="container" id="project-container">
                <h1 className="sub-title" id="project-heading">Neso Academy Website</h1>
                <br/>
                <p>Developed the code for the Neso Academy website<br/>
                    <br/> <i className="fa-solid fa-arrow-right"></i> Neso Academy offers resources on engineering courses, school syllabus, competitive exams, etc.  
                    <br/> <i className="fa-solid fa-arrow-right"></i> Developed the code for the <span id="external_link"><a href="https://www.nesoacademy.org/cs/03-operating-system" rel="noreferrer" target="_blank">Subject Page</a></span>, <span id="external_link"><a href="https://www.nesoacademy.org/search" rel="noreferrer" target="_blank">Search Page</a></span> and <span id="external_link"><a href="https://www.nesoacademy.org/cs/03-operating-system/ppts/01-introduction-and-basics" rel="noreferrer" target="_blank">PPT Viewing Page</a></span>.
                    <br/> <br/>
                    Please check out the demos of the Subject Page, PPT Viewing Page, and Search Page (in this order) in the videos (might have to use the full screen option because of the font) - 
                </p>
                <div className="services-list">
                    <div className="video-div driver-project">
                        <video controls>
                            <source src={One}/>
                            <p>Your browser does not support the video.</p>
                        </video>
                    </div>
                    <div className="video-div driver-project">
                        <video controls>
                            <source src={Two}/>
                            <p>Your browser does not support the video.</p>
                        </video>
                    </div>
                    <div className="video-div driver-project">
                        <video controls>
                            <source src={Three}/>
                            <p>Your browser does not support the video.</p>
                        </video>
                    </div>
                </div>
                <div id="know-more">
                    <h3>Want to know more? Click on the page below</h3>
                    <br/>
                    <a href="https://www.nesoacademy.org/" target="_blank" rel="noreferrer"><i className="fa-solid fa-file"></i></a>
                </div>
            </div>
        </div>
    </>
    )
}

export default NesoProject