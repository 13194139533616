function Experience() {
    return (
    <div className="tab-contents" id="experience">
        <ul>
            <li><span>July'23 - Current</span><br/>Associate Machine Learning Scientist at <span id="external_link"><a href="https://www.wadhwaniai.org/" target="_blank" rel="noreferrer">Wadhwani AI</a></span></li>
            <li><span>March'23 - April'23</span><br/>Machine Learning Intern at <span id="external_link"><a href="https://www.truefan.in/" target="_blank" rel="noreferrer">TrueFan</a></span></li>
            <li><span>April'22 - August'22</span><br/>Data Science Intern at <span id="external_link"><a href="https://www.mfilterit.com/" target="_blank" rel="noreferrer">mFilterIt</a></span></li>
            <li><span>Febuary'22 - March'22</span><br/>Data Science Intern at <span id="external_link"><a href="https://www.seekace.com/" target="_blank" rel="noreferrer">SeekACE</a></span></li>
            <li><span>December'20 - April'21</span><br/>Web Development Intern at <span id="external_link"><a href="https://www.nesoacademy.org/" target="_blank" rel="noreferrer">Neso Academy</a></span></li>

        </ul>
    </div>
    )
}

export default Experience