import Header from "./Header"
import About from "./About"
import Services from "./Services"
import MyWork from "./MyWork"
import Contact from "./Contact"

function Main() {
    return (
    <>
    <Header/>
    <About/>
    <Services/>
    <MyWork/>
    <Contact/>
    </>
    )
}

export default Main