function Skills() {
    return (
    <div className="tab-contents" id="skills">
        <ul>
            <li><span>Machine Learning</span><br/>Designing and Building Machine Learning Systems</li>
            <li><span>Deep Learning</span><br/>Implementing Neural Networks for Advanced Pattern Recognition</li>
            <li><span>Computer Vision</span><br/>Developing Solutions for Visual Data Interpretation and Automation.</li>
            <li><span>Full Stack Web Development</span><br/>Creating Seamless and User-Friendly Web Applications.</li>
        </ul>
    </div>
    )
}

export default Skills