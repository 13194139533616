function Services() {
    return (
    <div id="services">
        <div className="container">
            <h1 className="sub-title">My Services</h1>

            <div className="services-list">
                <div>
                    <i className="fa-solid fa-image"></i>
                    <h2>Computer Vision Applications</h2>
                    <p>Create applications for visual data interpretation, enabling automation and efficiency.</p>
                </div>
                <div>
                    <i className="fa-solid fa-brain"></i>
                    <h2>Machine Learning Solutions</h2>
                    <p>Develop custom machine learning systems tailored to your specific needs.</p>
                </div>
                <div>
                    <i className="fa-solid fa-code"></i>
                    <h2>Web Development Services</h2>
                    <p>Design and build responsive, user-friendly websites with a focus on functionality and aesthetics.</p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Services