import './App.css';
import Main from './Components/Main';
import CricketProject from './Components/CricketProject';
import DriverProject from './Components/DriverProject';
import NesoProject from "./Components/NesoProject.js"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="event-detection" element={<CricketProject/>}/>
        <Route path="driver-drowsiness-detection" element={<DriverProject/>}/>
        <Route path="neso-website" element={<NesoProject/>}></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
