import logo from "../Media/logo2.png"


function Header() {

    function openmenu(e) {
        const sidemenu = document.getElementById("sidemenu")
        sidemenu.style.right = "0"
    }

    function closemenu(e) {
        const sidemenu = document.getElementById("sidemenu")
        sidemenu.style.right = "-200px"
    }

    return (
    <div id="header">
        <div className="container">
            <nav>
                <a href="/"><img src={logo} className="logo" alt="mauj-logo"/></a>
                <ul id="sidemenu">
                    <li><a href="/">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#portfolio">Portfolio</a></li>
                    <li><a href="#contact">Contact</a></li>
                    <i className="fas fa fa-times" onClick={() => closemenu()}></i>
                </ul>
                <i className="fas fa-solid fa-bars" onClick={() => openmenu()}></i>
            </nav>
            <div className="header-text">
                <p>ML/DL Engineer</p>
                <h1>Hi, I'm <span>Mauj</span> Mishra <br/> From India</h1>
            </div>
        </div>
    </div>
    )
}

export default Header